import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import {
  content,  
  cover,
  container,
  center,
  flex,
  border,
  white,
  icon,
  larger,
  thin,
} from '../components/layout.module.css'

const Learnings = () => {
  return (
    <Layout pageTitle="Learnings">

      <div className={cover}>
        <StaticImage src="../images/learnings/banner.jpg" alt="" style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          filter: "brightness(0.75)",
          }}
          loading="eager"
        layout="fullWidth"></StaticImage>
        <div className={container +' '+ content}>
          <h1>Learnings</h1>
        </div>
      </div>

      <div className={container}>
        <h2>What we learned</h2><br/><br/>
        <h3>Tips for Social Service Organizations (SSOs)</h3>
        <p>Keep your mission and members’ needs at the center of partnerships with healthcare.  The goal of these partnerships is to create new opportunities to address social needs in ways that aren’t currently available.  Healthcare partnerships can be complex and time consuming but worthwhile when you focus on addressing root causes of poor health and inequity.</p>
      </div>
      <div className={container}>
        <div className={flex}>
          <div>
            <StaticImage src="../images/icons/sso_tips1.svg" placeholder="blurred" alt="" style={{width:'100px',height:'100px'}}></StaticImage>
            <p><strong className={larger}>Consider</strong><br/>
            how a partnership with a healthcare organization complements your organization’s mission and larger portfolio of services.</p>
          </div>
          <div>
            <StaticImage src="../images/icons/sso_tips2.svg" placeholder="blurred" alt="" style={{width:'100px',height:'100px'}}></StaticImage>
            <p><strong className={larger}>Engage</strong><br/>
            the front-line staff in program planning. If early conversations are held solely between leadership team members, there may be unforeseen challenges and workflow considerations during implementation.</p>
          </div>
          <div>
            <StaticImage src="../images/icons/sso_tips3.svg" placeholder="blurred" alt="" style={{width:'100px',height:'100px'}}></StaticImage>
            <p><strong className={larger}>Communicate</strong><br/>
            with staff and leaders at all levels of the organization. Make time to check in with front-line staff, solicit feedback, connect with managers and directors, and brainstorm strategies for improvement.</p>
          </div>
          <div>
            <StaticImage src="../images/icons/sso_tips4.svg" placeholder="blurred" alt="" style={{width:'100px',height:'100px'}}></StaticImage>
            <p><strong className={larger}>Work</strong><br/>
            with the healthcare partner to gain clarity on their referral workflow and program compliance needs, especially with regards to data security and patient privacy.</p>
          </div>
        </div>
        <div className={center+' '+thin}>
          <blockquote className={white}><p>I would encourage other SSOs to look at these types of partnership with healthcare for those additional services they will provide to your agency, those services that will really make a difference to the people you serve.<br/><strong>– Felisha Marshall, Director of Housing Supports, Metro Housing|Boston</strong></p></blockquote>
        </div>
      </div>

        <div className={container}>
          <h3>Tips for Healthcare Partners</h3>
        </div>
        <div className={container}>
          <div className={flex}>
            <div>
              <StaticImage src="../images/icons/understanding.svg" placeholder="blurred" alt="" style={{width:'100px',height:'100px'}}></StaticImage>
              <p><strong className={larger}>A Deep Understanding</strong><br/>
              Take the time to understand the SSO’s capacity and the systems and processes in place for activities like tracking data or invoicing for services. Understanding these processes early on will help assess the administrative support that is needed to meet the expectations of the partnership.  </p>
            </div>
            <div>
              <StaticImage src="../images/icons/nuances.svg" placeholder="blurred" alt="" style={{width:'100px',height:'100px'}}></StaticImage>
              <p><strong className={larger}>The Nuances Matter</strong><br/>
              Recognize the SSO as the expert and build the relationship as a partnership – not just a vendor of services. That may require taking time to better understand the social services sector, their values and priorities, funding models, and operations. This will allow you to better articulate the nuances of the healthcare sector, which will allow partners to engage in shared decision making as equals.</p>
            </div>
            <div>
              <StaticImage src="../images/icons/communication.png" placeholder="blurred" alt="" style={{width:'100px',height:'100px'}}></StaticImage>
              <p><strong className={larger}>Great Communication</strong><br/>
              Communicate with staff and leaders at all levels of the organization. Make time to check in with front-line staff about member cases and for feedback on workflows and processes. Connect with managers and directors to discuss operations, review outcomes, and brainstorm strategies for improvement.</p>
            </div>
          </div>
        </div>
        <div className={container+' '+center+' '+thin}>
          <blockquote className={white +' '+ border}><p>There is value in a partner being able to do something really well for a component of your membership.<br/><strong>– Jennifer Obadia, Senior Director Health Care Partnerships, Project Bread</strong></p></blockquote>
        </div>


      <div className={container +' '+ center}>
        <h2>What Can You Do Next?</h2>
        <p className={larger +' '+ thin}>Many of us in the healthcare sector have long recognized that patients’ social health is foundational to good physical and behavioral health and well-being. The approach championed in the Flexible Services Program – and demonstrated by us at C3 and our partners – is the future of healthcare. You are a critical part of it. Here are some ideas for what you can do next.</p>
      </div>

      <div className={container}>
        <h3>Healthcare Partners</h3>
        <div className={flex}>
          <StaticImage src="../images/learnings/partners.jpg" alt="" objectFit="contain"></StaticImage>
          <div>
            <p><strong>Understand the social issues in your community that are faced by the people you care for,</strong> like housing instability and homelessness, unemployment, food insecurity, transportation challenges, lack of affordable childcare and other foundational challenges. This is a first step for addressing the immediate needs of your members or patients can help you better understand and find a role for yourself in supporting the solutions that will address the root causes of health inequity.</p>
            <p><strong>Take the first step: have coffee with a social service organization serving your community.</strong> Use this time to talk about your respective missions, the shared community you serve, and how the SSO supports the health of the community, your patients, and their families.</p>
            <p><strong>Build community organizations into your community health needs assessment and community health improvement planning processes.</strong> These organizations have the expertise and the relationships with individuals who are experiencing the consequences of a system that is failing to address their health-related social needs.</p>
          </div>
        </div>
      </div>

      <div className={center}>
        <div className={container}>
          <h3>Social Service Organizations</h3>
        </div>
        <div className={container +' '+ flex}>
          <div>
            <StaticImage src="../images/icons/sso1.svg" alt="" className={icon} style={{width:'100px',height:'100px'}}></StaticImage>
            <p>Get to know the healthcare partners in your community and build relationships with them.</p>
          </div>
          <div>
            <StaticImage src="../images/icons/sso2.svg" alt="" className={icon} style={{width:'100px',height:'100px'}}></StaticImage>
            <p>Create a cheat sheet to use in conversations with potential healthcare partners – articulate your expertise, your current capacity, and the systems/processes you have in place.</p>
          </div>
          <div>
            <StaticImage src="../images/icons/sso3.svg" alt="" className={icon} style={{width:'100px',height:'100px'}}></StaticImage>
            <p>Invite healthcare partners in your community to serve on your Board of Directors.</p>
          </div>
          <div>
            <StaticImage src="../images/icons/sso4.svg" alt="" className={icon} style={{width:'100px',height:'100px'}}></StaticImage>
            <p>Advocate for SSOs as experts in their given field.</p>
          </div>
        </div>
      </div>
    
    </Layout>
  )
}

export default Learnings